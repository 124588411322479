.footerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 150px;
  background-color: #03070a;
  padding-top: 2rem;
}

.logoContainer {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  color: white;
  border-top: 2px solid rgba(255, 255, 255, 0.35);
  opacity: 0.25
}

.logoContainer > a{
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
  }
}

.logoSpan{
  font-weight: bold;
  //font-family: Calibri, Arial, sans-serif;
  font-size: 36px;
  font-style: italic;
  letter-spacing: -0.05em;
}

.appsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.singleAppContainer {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50% 50%;
  background-color: #f5f5f5;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: 0.1s all linear;
  &:hover {
    background-color: #f5f5f5CC;
    transform: scale(1.1);
  }
}

.icon {
  font-size: 1.5rem;
  color: black;
}