.row {
  display: flex;
  flex-direction: row;
  padding: 1rem
}

.mainContainer {
  width: 1440px;
  background-color: red;
}

.w-250 {
  width: 250px
}